/**
 * Helper class to do common analytics tracking across both GA and Matomo.
 */
var Analytics = /** @class */ (function () {
    function Analytics() {
        this.isGAEnabled = 'gtag' in window;
        this.isMatomoEnabled = '_paq' in window;
    }
    Analytics.prototype.matomo = function (x) {
        if (this.isMatomoEnabled) {
            // @ts-ignore
            window._paq.push(x);
        }
    };
    Analytics.prototype.gtag = function () {
        var x = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            x[_i] = arguments[_i];
        }
        if (this.isGAEnabled) {
            // @ts-ignore
            window.gtag.apply(window, x);
        }
    };
    Analytics.prototype.trackPageView = function () {
        this.matomo(['trackPageView']);
        this.gtag('event', 'page_view');
    };
    Analytics.prototype.trackSiteSearch = function (keyword, category, searchCount) {
        this.matomo(['trackSiteSearch', keyword, category, searchCount]);
        this.gtag('event', 'site_search', { keyword: keyword, category: category, searchCount: searchCount });
    };
    Analytics.prototype.trackEvent = function (category, action, name, value) {
        this.matomo(['trackEvent', category, action, name, value]);
        this.gtag('event', action, { event_category: category, event_name: name, value: value });
    };
    /**
     * Submit analytics events for clickable elements with data-track-event="Cat | Action | Name" attributes.
     */
    Analytics.prototype.trackButtonEvents = function () {
        var _this = this;
        document.addEventListener('click', function (e) {
            if (e.target && e.target instanceof Element) {
                // find the closest element with the data-track-event attribute
                var trackable = e.target.closest('[data-track-event]');
                if (trackable) {
                    var data = trackable.getAttribute('data-track-event');
                    if (data && data.includes('|')) {
                        var _a = data.split('|').map(function (s) { return s.trim(); }), category = _a[0], action = _a[1], name_1 = _a[2];
                        _this.trackEvent(category, action, name_1);
                    }
                }
            }
        });
    };
    return Analytics;
}());
export { Analytics };
var analytics = new Analytics();
export default analytics;
